import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

// This example assumes that any internal link (intended for Gatsby)
// will start with exactly one slash, and that anything else is external.
const REGEX_INTERNAL_URL = /^\/(?!\/)/

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.text} className="column is-6">
        <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: '240px',
                display: 'inline-block',
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <p>{item.text}</p>
	  {REGEX_INTERNAL_URL.test(item.linkurl) ? (
              <Link className="button" to={item.linkurl}>
                {item.linktext}
              </Link>
            ) : (
              <a href={item.linkurl}>{item.linktext}</a>
            )}
        </section>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      linkurl: PropTypes.string,
      linktext: PropTypes.string,
    })
  ),
}

export default FeatureGrid
